<template>
<ul class="accordion w-100">
  <AccordionItem
    v-for="(accordionItem, index) in itemList"
    :key="index"
  >
    <template #accordion-title="{ visible }">
      <VSheet class="d-flex align-center gap-5 justify-space-between relative">
        <span class="primary-content font-weight-600">
          {{ accordionItem.title }}
        </span>
        <VImg
          width="24"
          height="24"
          src="accordion_arrow.svg"
          :class="[
            'small-arrow',
            visible ? 'rotate-180' : ''
          ]"
        />
      </VSheet>
    </template>
    <template #accordion-content>
      <div class="custom-divider" />
      <div
        class="primary-content overflow-hidden my-5"
        v-html="accordionItem.content"
      />
    </template>
  </AccordionItem>
</ul>
</template>

<script src="./accordion-component.js"></script>

<style lang="scss" scoped>
.custom-divider {
  max-width: 1000px !important;
}
</style>
