import AccordionItem from '../accordion-item/AccordionItem.vue'

export default {
  components: {
    AccordionItem
  },
  props: {
    itemList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    AccordionComponent: {
      count: 0,
      active: null
    }
  }),
  provide () {
    return { AccordionComponent: this.AccordionComponent }
  }
}
