import AccordionComponent from './accordion/accordion-component/AccordionComponent.vue'

import { ABOUT_CONTENT } from '@/data/data.js'

export default {
  name: 'ProblemDescription',
  components: {
    AccordionComponent
  },
  data: () => ({
    content: ABOUT_CONTENT
  })
}
