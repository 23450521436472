<template>
  <li class="accordion__item d-block">
    <div
      :class="[
        'accordion__trigger',
        { 'accordion__trigger_active': visible },
      ]"
      @click="open"
    >
      <div class="pointer">
        <slot name="accordion-title" :visible="visible" />
      </div>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="accordion__content" v-show="visible">
        <slot name="accordion-content" />
      </div>
    </transition>
  </li>
</template>

<script src="./accordion-item.js"></script>

<style lang="scss" scoped>
.accordion__item {
  border-radius: 10px;
  border: 1px solid rgba(57, 54, 78, 0.10);
  transition: .2s;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0px;
  }
}
.accordion__trigger {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  user-select: none;
}
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: all 0.3s ease;
  overflow: hidden;
}
.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
