<template>
<div class="d-flex align-center gap-5">
  <a
    v-for="social in socials"
    :key="social.id"
    target="_blank"
    :data-action="social.id === 5 ? 'share/whatsapp/share' : ''"
    :href="getShareLink(social)"
  >
    <VImg
      isIcon
      width="32" height="32"
      :src="social.src"
      :background="hoveredSocialIcon === social.id ? 'secondary' : 'primary'"
      @mouseover="onMouseover(social.id)"
      @mouseleave="onMouseleave"
      class="social-icon pointer"
    />
  </a>
  <VImg
    isIcon
    width="32" height="32"
    src="copy_icon.svg"
    :background="hoveredSocialIcon === 'copy_icon' ? 'secondary' : 'primary'"
    @mouseover="onMouseover('copy_icon')"
    @mouseleave="onMouseleave"
    @click="copy"
    class="social-icon pointer"
  />
</div>
</template>

<script src="./poster-socials.js"></script>
