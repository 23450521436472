<template>
<div class="poster-section relative">
  <VImg
    v-if="mdAndUp"
    isCover
    width="100%" height="80"
    src="poster_section_pixel.png"
    class="poster-pixel"
  />
  <div class="container">
    <div class="content">
      <div :class="[
          'd-flex justify-center gap-10',
          smAndDown ? 'flex-column' : ''
        ]"
      >
        <h2
          v-if="smAndDown"
          class="primary-title text-center"
        >
          Скачай постер і стань нашим амбасадором
        </h2>
        <div class="left-col">
          <VCarousel
            indicators
            :slides="posters"
            height="480"
          >
            <template v-slot="{ carouselItem }">
              <VImg
                width="320" height="452"
                :src="carouselItem.src"
                class="poster"
              />
            </template>
          </VCarousel>
          <VBtn
            :block="smAndDown"
            width="320"
            class="primary-btn mt-10"
            @click=openDialog
          >
            <span class="light-dark--text">
              Завантажити
            </span>
          </VBtn>
        </div>
        <div v-if="mdAndUp" class="pt-20">
          <h2 class="primary-title pb-20">
            <span>
              Скачай постер
            </span>
            <br />
            <span>
              і стань нашим амбасадором
            </span>
          </h2>
          <div class="pt-20">
            <h3 class="primary-subtitle">
              Поділися посиланням на проєкт #БезБолюUA
            </h3>
            <PosterSocials class="mt-5" />
          </div>
        </div>
        <VImg
          v-if="smAndDown"
          isCover
          width="100svw" height="80"
          src="poster_section_pixel.png"
          class="poster-pixel-mobile"
        />
        <h3
          v-if="smAndDown"
          class="primary-subtitle text-center"
        >
          Поділися посиланням на проєкт #БезБолюUA
        </h3>
        <PosterSocials
          v-if="smAndDown"
          class="socials d-flex gap-5 justify-center flex-wrap"
        />
      </div>
    </div>
  </div>
  <VDialog
    v-model="isShowDialog"
    @close-dialog="closeDialog"
    class="poster-dialog"
  >
    <PosterPopup :posters="posters" />
  </VDialog>
</div>
</template>

<script src="./poster-section.js"></script>

<style lang="scss" scoped>
.poster-pixel {
  position: absolute;
  top: 314px;
  left: 50%;
  transform: translateX(-50%);
}
.container {
  margin-top: 38px;
}

.left-col {
  width: 320px;
}

.poster {
  border-radius: 15px;
  overflow: hidden;
}

@media only screen and (max-width: $sm) {
  .container {
    margin-top: 0px;
  }

  .left-col {
    width: 100%;
  }

  .poster {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .poster-pixel-mobile {
    margin-left: -20px;
    margin-right: -20px;
  }

  .socials {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .poster-dialog ::v-deep(.v-dialog-content) {
    top: 80px;
    transform: translate(-50%, 0%);
  }
}
</style>
