export default {
  inject: ['AccordionComponent'],
  data: () => ({
    index: null
  }),
  mounted () {
    this.index = this.AccordionComponent.count++
  },
  computed: {
    visible () {
      return this.index == this.AccordionComponent.active
    }
  },
  methods: {
    open () {
      if (this.visible) this.AccordionComponent.active = null
      else this.AccordionComponent.active = this.index
    },
    start (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end (el) {
      el.style.height = null
    }
  }
}
