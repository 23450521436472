<template>
<div class="help-step w-100 relative">
  <div class="shadow" />
  <VSheet
    width="100%"
    height="204"
    class="help-step-content overflow-hidden relative pt-9"
  >
    <VImg
      v-if="step.src"
      isCover
      width="100%" height="100%"
      :src="step.src"
      class="step-bg"
    />
    <span class="d-block text-center white--text text-body-0 font-weight-bold relative">
      {{ step.value }}
    </span>
    <div class="divider white-02 relative" />
    <div
      class="primary-content text-center"
      v-html="step.desc"
    />
  </VSheet>
</div>
</template>

<script src="./help-step.js"></script>

<style lang="scss" scoped>
.help-step {
  .shadow {
    position: absolute;
    width: calc(100% - 50px);
    height: 100%;
    left: 25px;
    bottom: 0px;
    box-shadow: 0px 20px 30px rgba(43, 40, 40, 0.3);
  }

  .help-step-content {
    border-radius: 15px;
  }

  .step-bg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .divider {
    height: 1px;
    width: 100px;
    margin: 0px auto 20px;
  }

  .primary-content {
    color: $white !important;
    position: relative;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
</style>
