import SocialsComponent from '@/components/socials-component/SocialsComponent.vue'
import VideoComponent from '@/components/video-component/VideoComponent.vue'

export default {
  name: 'PreviewSection',
  components: {
    SocialsComponent,
    VideoComponent
  },
  data: () => ({
    INTERVAL_INTENSITY: 6000,
    currentSlide: null,
    isLogoHovered: false,
    slides: [
      {
        id: 1,
        src: 'preview_image_1.jpg'
      },
      {
        id: 2,
        src: 'preview_image_2.jpg'
      },
      {
        id: 3,
        src: 'preview_image_3.jpg'
      },
      {
        id: 4,
        src: 'preview_image_4.jpg'
      }
    ]
  }),
  created () {
    this.currentSlide = this.slides[0],
    this.runInterval()
  },
  methods: {
    runInterval () {
      setInterval(() => {
        let currentIndex = this.slides.indexOf(this.currentSlide)
        let nextIndex = (currentIndex + 1) % this.slides.length
        this.currentSlide = this.slides[nextIndex]
      }, this.INTERVAL_INTENSITY)
    }
  }
}
