<template>
<div class="help-path-section">
  <div class="container">
    <div class="content relative">
      <h2 class="primary-title text-center mb-10">
        Щоб отримати допомогу достатньо 3-х кроків:
      </h2>
      <VCarousel
        v-if="smAndDown"
        indicators
        controls
        :slides="steps"
        height="252"
      >
        <template v-slot="{ carouselItem }">
          <HelpStep :step="carouselItem" />
          <span
            v-if="carouselItem.id === 3"
            class="step-desc absolute secondary-content"
          >
          * у будні з 10.00 до 17.00
        </span>
        </template>
      </VCarousel>
      <div
        v-if="mdAndUp"
        class="d-flex justify-space-between gap-4 relative"
      >
        <HelpStep :step="steps[0]" />
        <HelpStep :step="steps[1]"/>
        <HelpStep :step="steps[2]" />
        <span class="step-desc absolute secondary-content">
          * у будні з 10.00 до 17.00
        </span>
      </div>
      <div :class="[
          'd-flex justify-center',
          smAndDown ? 'mt-5' : 'mt-10'
        ]"
      >
        <a
          target="_blank"
          href="https://t.me/stoppainuabot"
          :class="[
            smAndDown ? 'd-block' : 'd-inline-block'
          ]"
        >
          <VBtn
            :block="smAndDown"
            width="332"
            class="primary-btn"
          >
            <span class="dark--text">
              Отримати допомогу
            </span>
          </VBtn>
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script src="./help-path-section.js"></script>

<style lang="scss" scoped>
.step {
  border-radius: 15px;
  background: linear-gradient(225deg, #DBC9B0 0%, #9E9681 100%);
}

.step-desc {
  top: calc(100% + 40px);
  right: 81px;
}

@media only screen and (max-width: $sm) {
  .step-desc {
    top: initial;
    bottom: 14px;
  }

  .step-desc {
    right: 50%;
    transform: translateX(50%);
  }
}

</style>
